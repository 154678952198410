import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Slider.css";
import axios from "axios";
import BASEURL from "../../../../BaseUrl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const PrevArrow = (props) => (
  <div {...props} className="custom-arrow custom-prev">
    {/* Previous */}
  </div>
);

const NextArrow = (props) => (
  <div {...props} className="custom-arrow custom-next">
    {/* Next */}
  </div>
);

const SimpleSlickCarousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dotsColor: "orange",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000, // Set the time between each slide in milliseconds (e.g., 3000ms = 3 seconds)
  };

  const cardData = [
    {
      title: "Heading",
      description:
        "Lexis+ AI delivers trusted AI informed by your firm documents",
    },
    {
      title: "Heading",
      description:
        "Lexis+ AI delivers trusted AI informed by your firm documents",
    },
    {
      title: "Heading",
      description:
        "Lexis+ AI delivers trusted AI informed by your firm documents",
    },
    {
      title: "Heading",
      description:
        "Lexis+ AI delivers trusted AI informed by your firm documents",
    },
    {
      title: "Heading",
      description:
        "Lexis+ AI delivers trusted AI informed by your firm documents",
    },
    // Siding Estimates
    // Add more card data as needed
  ];
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BASEURL}/api/mbjBlogs/getAllBlogs`);
        setData(response.data.data);
        console.log(response.data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div
      className="service-slider"
      style={{ maxWidth: "800px", margin: "0 auto", position: "relative" }}
    >
      <h2 style={{ textAlign: "center" }}>The Latest</h2>
      <p style={{ textAlign: "center" }}>
        Join us and augment your litigation with one the finest collection of
        Citations and Judicial Decisions
      </p>
      <Slider {...settings} ref={sliderRef}>
        {data?.map((card) => (
          <div key={card.id}>
            <Card
              sx={{
                margin: "20px",
                textAlign: "center",
                // padding: "5px",
                // paddingTop: "40px",
                paddingBottom: "40px",
                borderRadius: "20px",
                height: "200px",
                border: "1px solid #F8FFD2",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.4)",
                "&:hover": {
                  // backgroundColor: "#f36e21", // Change to the desired hover color
                  cursor: "pointer", // Optional: Change cursor style on hover
                },
              }}
            >
              <img
                style={{ width: "100%", height: "120px" }}
                // src="https://imgv3.fotor.com/images/slider-image/A-clear-close-up-photo-of-a-woman.jpg"
                src={`https://api.taxpress.com.pk/${card?.file}`}
                alt="thumbnail"
              />
              <CardContent>
                <Typography fontWeight={"bold"} variant="h7" component="div">
                  {card.title}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", textAlign: "justify" }}
                  variant="body2"
                >
                  {card.description}
                </Typography>
              </CardContent>

              <p
                style={{ color: "blue", textDecoration: "underline" }}
                onClick={() => {
                  if (isLoggedIn) {
                    navigate("/library/blog-details", {
                      state: {
                        card: card,
                      },
                    });
                  } else {
                    navigate("/blog-details", {
                      state: {
                        card: card,
                      },
                    });
                  }
                }}
              >
                Read More
              </p>
            </Card>
          </div>
        ))}
      </Slider>
      <div
        className="custom-arrow custom-prev"
        onClick={() => sliderRef.current.slickPrev()}
        style={{
          position: "absolute",
          top: "50%",
          left: "-20px",
          cursor: "pointer",
        }}
      >
        <ArrowBackIosIcon />
      </div>
      <div
        className="custom-arrow custom-next"
        onClick={() => sliderRef.current.slickNext()}
        style={{
          position: "absolute",
          top: "50%",
          right: "-20px",
          cursor: "pointer",
        }}
      >
        <ArrowForwardIosIcon />
      </div>
    </div>
  );
};

export default SimpleSlickCarousel;
