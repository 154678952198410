import AxiosCall from "../../api/AxiosCall";
import { errorToast, successToast } from "../../global/toast";
import { setAddToCart, setUser } from "./userReducer";

export const userLogin = (user, navigate) => (dispatch) => {
  AxiosCall.post("/users/login", user)
    .then((res) => {
      if (res.data.success) {
        const { user_role } = res.data.data;

        if (user_role.toLowerCase() === "ntn") {
          errorToast("Invalid credentials");
        } else if (user_role.toLowerCase() === "library") {
          dispatch(setUser(res.data.data));
          navigate("/library/dashboard");
          successToast("Login Successful");
        }
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
      errorToast(err.response.data.message);
    });
};

export const getAddToCart = (user, navigate) => (dispatch) => {
  AxiosCall.get(`/user/ntn/${user.id}`)
    .then((res) => {
      dispatch(setAddToCart(res.data.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const userRegister = (user, navigate) => (dispatch) => {
  AxiosCall.post("/users/register", {
    user: {
      email: user.email,
      password: user.password,
      username: user.username,
      address: user.phone,
      type: user.type,
    },
  })
    .then((res) => {
      if (res.data.success) {
        navigate("/");
        successToast(" Registration Successful. Please Login.");
      } else {
        errorToast(res.data.message);
      }
    })
    .catch((err) => {
      errorToast(err.response.data.message);
    });
};
