import React, { useEffect } from "react";
import HeroSections from "../../global/components/hero-section/HeroSections";
import BlogsSection from "./blogs-section/BlogsSection";
import { useSelector } from "react-redux";

const Blogs = () => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const a = "Home > Blogs";
  return (
    <>
      {!isLoggedIn && (
        <div
          style={{
            borderTop: "1px solid black",
            // height: "70px",
            backgroundColor: "#323232",
            paddingLeft: "50px",
            color: "white",
          }}
        >
          <div style={{ maxWidth: "1250px", margin: "0 auto", padding: "5px" }}>
            {/* <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Login</h1> */}
            {/* <br /> */}
            <p>{a}</p>
          </div>
        </div>
      )}

      {/* <HeroSections pageName={'Blogs'} /> */}
      <BlogsSection />
    </>
  );
};

export default Blogs;
