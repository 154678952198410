import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Btn,
  Container,
  ErrorText,
  Input,
  LoginRegistrationContainer,
} from "../../global/style/StyleComponent";
import { userLogin } from "../../redux-store/user/actionCreator";
import "./lexpress.css";
import Card2 from "./components/LexPress/Card2";
import Slider from "./components/LexPress/Slider";
import Card1 from "./components/LexPress/Card1";
import Card3 from "./components/LexPress/Card3";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ backgroundColor: "#F5F5F7" }}>
      <div className="lex-container">
  
        <div className="lex-form">
          <h2 style={{ color: "black", textAlign: "center", marginBottom: 0 }}>
            Welcome to mbjlaw
          </h2>
          {/* <p
            style={{
              color: "black",
              textAlign: "center",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            A delectus expedita sit, maiores voluptas aspernatur. <br />
            bcaecati culpa officia voluptatibus magnam fugit
          </p> */}
          <h3 style={{ textAlign: "center", color: "black" }}>
            Login to Our System
          </h3>

          <Input
            id="email"
            label="Email"
            placeholder="User Name"
            onChange={(e) => setEmail(e.target.value)}
            style={{
              background: "white",
              border: "none",
              opacity: 1,
              filter: "drop-shadow(-2px 9px 3px #c0afaf",
              marginBottom: "20px",
            }}
          />
          <ErrorText>{error.email}</ErrorText>
          <Input
            id="password"
            label="Password"
            type={"password"}
            placeholder="Password"
            style={{
              background: "white",
              border: "none",
              opacity: 1,
              filter: "drop-shadow(-2px 9px 3px #c0afaf",
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <ErrorText>{error.password}</ErrorText>
          <Container alignItem={true} marginBottom="10px">
            <Container
              justifyContent="flex-start"
              alignItem={true}
              width="auto"
            >
              <Input
                id="remember"
                width="auto"
                type={"checkbox"}
                marginBottom="0px"
              />
              <label htmlFor="remember">Remember me</label>
            </Container>
            <span style={{ cursor: "pointer" }}>Forget Password</span>
          </Container>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Btn
              type="submit"
              id="login-btn"
              onClick={() => {
                var mailFormat =
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (email === "") {
                  setError({
                    ...error,
                    email: "Email is required",
                    password: "",
                  });
                } else if (!email.match(mailFormat)) {
                  setError({
                    ...error,
                    email: "Email is not valid",
                    password: "",
                  });
                } else if (password === "") {
                  setError({ email: "", password: "Password is required" });
                } else {
                  setError({ email: "", password: "" });
                  dispatch(
                    userLogin(
                      {
                        username: email,
                        password: password,
                      },
                      navigate
                    )
                  );
                }
              }}
              fontSize="18px"
            >
              Login
            </Btn>
          </div>
          <span
            style={{ color: "black", textAlign: "center", marginTop: "10px" }}
          >
            Don't have account yet?
          </span>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link
              to="/registration"
              style={{
                textDecoration: "none",
                color: isHovered ? "yellow" : "black", // Change 'blue' and 'black' to your desired colors
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Sign Up
            </Link>
          </div>
        </div>
        <div
          className="lex-text"
        >
          Case Law At <br /> Your Finger Tips
        </div>
      </div>
      <div
        style={{ backgroundColor: "#323232", height: "20px", color: "#323232" }}
      >
        ojfhjgfdkgd
      </div>
      <Card1 />
      <Card2 />
      <Slider />
      <Card3 />
    </div>
  );
};

export default HomePage;
