import { Grid } from "@mui/material";
import React from "react";
import img from "../../../../assets/04.png";

const Card3 = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        marginTop: "50px",
        padding: "50px",
        paddingBottom: 0,
        marginBottom: "-5px",
      }}
    >
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <img className='card3-image' src={img} alt="img" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <h2>We have got all that you need</h2>
            <h3>Get help with your legal need</h3>
            <p>
              Our case Law library comprises a vast database of updated tax and
              legal citations with an additional feature of on demand citations
              with our team out there for your live support.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                marginTop: "50px",
              }}
            >
              <div></div>
              <button
                className="explore-button"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Optional for smooth scrolling
                  });
                  setTimeout(() => {
                    window.alert("Please Login");
                  }, 1000);
                }}
              >
                Explore Library
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Card3;
