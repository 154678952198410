import React from "react";
import "./styles.css";
import img from "../../../../assets/02.png";

const CustomLayout = () => {
  return (
    <div className="custom-container">
      <div className="left-content">
        <h2>Why Mbjlaw</h2>
        <p>
          Mbjlaw aims to make available the best and finest collection of the
          judicial decisions to all practicing members of the fraternity. Our
          data base embodies all the federal and provincial statutes and cases
          related to these statutes including but not limited to Taxation,
          Copyrights, Labour Laws. <br /> Mbjlaw visualizes to be a vital partner in
          the facilitation of decision making by the legal, academic and
          business fraternity across Pakistan. Our user friendly interface would
          help you navigate with absolute ease and provide you solutions round
          the clock.
        </p>
        <button
          className="explore-button"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional for smooth scrolling
            });
            setTimeout(() => {
              window.alert("Please Login");
            }, 1000);
          }}
        >
          Explore Library
        </button>
      </div>
      <div className="right-content">
        <img
          src='https://res.cloudinary.com/ddbbm971b/image/upload/v1728508426/lexpress2_vrdx5p.jpg' // Replace with the actual image URL
          alt="Overlapping"
          className="overlapping-image"
        />
      </div>
    </div>
  );
};

export default CustomLayout;
