import React from 'react';
import { useSelector } from 'react-redux';
import CustomPaginationActionsTable from '../../components/table/Table';

const NotificationList = () => {
  const searchedNotification = useSelector(
    (state) => state.library.searchedNotification
  );
  return (
    <div>
      <h3>Searched Notification</h3>
      <CustomPaginationActionsTable
        rows={searchedNotification}
        head={[
          { title: 'MBJlaw#', width: '5%', key: 'id' },
          { title: 'SRO', width: '20%', key: 'sro_no' },
          { title: 'Subject', width: '60%', key: 'subject' },
          { title: 'Download', width: '10%' },
        ]}
      />
    </div>
  );
};

export default NotificationList;
