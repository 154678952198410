import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/MBJ.png";
import { setAppointment } from "../../../redux-store/home/homeReducer";
import { Btn, MainContainer } from "../../style/StyleComponent";
import Appointment from "../appointment/Appointment";
import "./NavHeader.css";

const NavHeader = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector((state) => state.users.user);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  return (
    <div style={{ backgroundColor: "#323232", opacity: 0.9 }}>
      <MainContainer
        id="navHeaderContainer"
        bgColor="#323232"
        flexDirection="row"
        // paddingVertical="15px"
        justifyContent="space-between"
        alignItem="center"
        style={{ maxWidth: "1200px", margin: "0 auto", opacity: 0.9 }}
      >
        <div id="menuIcon" onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={faBars} color="#9e742c" />
        </div>

        <Link id="logoHead" to={isLoggedIn ? "/library/dashboard" : "/"}>
          <img
            style={{ height: "50px", width: "auto" }}
            src={Logo}
            alt="Logo"
          />
        </Link>
        {width > 550 || isOpen ? (
          <div className="navItemsContainer">
            <Link
              onClick={() => alert("Please login")}
              to={"/"}
              className="navBtn"
            >
              Case Laws
            </Link>
            <Link
              onClick={() => alert("Please login")}
              to="/"
              className="navBtn"
            >
              Statutes
            </Link>

            <Link
              onClick={() => alert("Please login")}
              to="/"
              className="navBtn"
            >
              Notifications
            </Link>
            <Link
              onClick={() => alert("Please login")}
              to="/"
              className="navBtn"
            >
              Dictionary
            </Link>
            <Link to="/contact" className="navBtn">
              Contact
            </Link>
            {/* {!isLoggedIn && (
              <Btn
                id="appointmentBtn"
                width="fit-content"
                fontSize="16px"
                style={{ paddingLeft: "70px", paddingRight: "70px",height:'50px'  }}
                onClick={() => {
                  // dispatch(setAppointment());
                  navigator("/registration");
                }}
              >
                Registeration
              </Btn>
            )} */}
          </div>
        ) : null}
      </MainContainer>
      <Appointment />
    </div>
  );
};

export default NavHeader;
