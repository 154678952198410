import React from "react";
import { useSelector } from "react-redux";
import CustomPaginationActionsTable from "../../components/table/Table";
// import * as React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StatuesList = () => {
  const searchedStatute = useSelector((state) => state.library.searchedStatute);
  console.log(searchedStatute[0]?.file);
  const uri = `https://api.taxpress.com.pk/${searchedStatute[0]?.file}`;

  return (
    <div>
      <h3>Searched Statutes</h3>
      {/* <a
        href="https://api.taxpress.com.pk/upload/image/taxpress_1709651341029.pdf"
        target="_blank"
      >
        download
      </a> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>MBJlaw#</TableCell>
              <TableCell>Law/Statute</TableCell>
              <TableCell>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell>{searchedStatute[0]?.id}</TableCell>
            <TableCell>{searchedStatute[0]?.law_or_statute}</TableCell>
            <TableCell>
              {searchedStatute[0] && (
                <a href={uri} target="_blank">
                  download
                </a>
              )}
            </TableCell>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <CustomPaginationActionsTable
        rows={searchedStatute}
        head={[
          { title: 'Taxpress#', width: '5%', key: 'id' },
          { title: 'Law/Statute', width: '30%', key: 'law_or_statute' },
          { title: 'Download', width: '10%' },
        ]}
      /> */}
    </div>
  );
};

export default StatuesList;
