import AxiosCall from '../../api/AxiosCall';
import { setBlogs, setResearch } from './blogReducer';

export const getResearchAndInsight = () => (dispatch) => {
  AxiosCall.get('/insights/getAllInsights')
    .then((res) => {
      const data = res.data.data;
      data.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      dispatch(setResearch(data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllBlog = () => (dispatch) => {
  AxiosCall.get('/mbjBlogs/getAllBlogs')
    .then((res) => {
      const data = res.data.data;
      data.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      dispatch(setBlogs(data));
    })
    .catch((err) => {
      console.log(err);
    });
};
