import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  MainContainer,
} from "../../../../global/style/StyleComponent";
import {
  LibraryCardsContainer,
  LibraryListCard,
  LibraryListsContainer,
  ResearchContainer,
  ResearchHeading,
} from "../../../../pages/research-add-insight/ResearchAndInsightStyle";
import { getDashboard } from "../../../../redux-store/library/actionCreator";
import { ReactComponent as file } from "../../../../assets/fileicon.svg";
import { Card, Grid } from "@mui/material";
// import Card from "./components/Card";

const Dashboard = () => {
  const data = useSelector((state) => state.library.dashboard);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const dispatch = useDispatch();
  function DownloadFile(fileName) {
    fetch(`${process.env.REACT_APP_FILE_DOMAIN}${fileName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "taxpress.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  }
  useEffect(() => {
    dispatch(getDashboard());
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);
  const user = useSelector((state) => state.users.user);

  const onCheckLogin = (link) => {
    if (!isLoggedIn || user.user_role !== "library") {
      navigate("/");
    } else {
      if (typeof link === "string") DownloadFile(link);
    }
  };

  function DownloadPdf(fileData) {
    // console.log("on click py mil rha hoon", fileData);
    // const encodedFileData = encodeURIComponent(fileData);
    // console.log(encodedFileData);
    navigate("/library/pdf-generater", {
      state: {
        // ...values
        fileData: fileData,
      },
    });

    // fetch(`${fileName}`)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = "taxpress.pdf";
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   });
  }

  function DownloadFilePdf(fileData) {
    // console.log("on click py mil rha hoon", fileData);
    // const encodedFileData = encodeURIComponent(fileData);
    // console.log(encodedFileData);
    navigate("/library/pdf-generater", {
      state: {
        // ...values
        fileData: fileData,
      },
    });

    // fetch(`${fileName}`)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = "file.pdf";
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   });
  }
  return (
    <ResearchContainer>
      <MainContainer
        id="libraryDashboardHeader"
        paddingVertical={"4em"}
        alignItem={"center"}
        flexDirection={"row"}
      >
        <Container
          id="countHeading"
          direction={"column"}
          flex={"1"}
          justifyContent={"center"}
          marginRight={"1em"}
        >
          <ResearchHeading>
            Explore Our Vast Range of Tax and Legal Citation
          </ResearchHeading>
          <p>
            Pakistan's #1 portal introducing on demand feature for Court
            Precedents and Legal citations encompassing all tax and fiscal laws.
            Our case law library comprises a vast database of updated tax and
            legal citations with an additional feature of on demand citations
            with our team out there for your live support.
          </p>
        </Container>
      </MainContainer>
      <MainContainer paddingVertical={"2em"}>
        <Grid container>
          <Grid container sm={12} md={12} lg={6} xl={6} spacing={2} mb={10} justifyContent={'center'}>
            <Grid item sm={12} md={12} lg={12} xl={6} >
              <Card sx={{ p: "7px", height: "200px", width:'250px' }}>
                <h2 style={{ marginTop: "3px" }}>Total Cases</h2>
                <div
                  style={{
                    height: "80%",
                    padding: "20px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <h3>1</h3>
                </div>
              </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Card sx={{ p: "7px", height: "200px",width:'250px' }}>
                <h2 style={{ marginTop: "3px" }}>Statutes</h2>
                <div
                  style={{
                    height: "80%",
                    padding: "20px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <h3>4</h3>
                </div>
              </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Card sx={{ p: "7px", height: "200px",width:'250px' }}>
                <h2 style={{ marginTop: "3px" }}>Notifications</h2>
                <div
                  style={{
                    height: "80%",
                    padding: "20px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <h3>12</h3>
                </div>
              </Card>
            </Grid>
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Card sx={{ p: "7px", height: "200px",width:'250px' }}>
                <h2 style={{ marginTop: "3px" }}>Dictionary</h2>
                <div
                  style={{
                    height: "80%",
                    padding: "20px",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <h3>3</h3>
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={6} xl={6}>
            <LibraryListCard style={{ marginLeft: "10px", height: "420px" }}>
              <strong
                style={{
                  backgroundColor: "#3C3D37",
                  position: "sticky",
                  top: 0,
                  color: "#fff",
                }}
              >
                Case Laws
              </strong>
              {data !== undefined &&
                data.caseLaws !== undefined &&
                data?.caseLaws.map((item) => (
                  // <div
                  //   onClick={() => {
                  //     console.log("Mil to rhi hy file", item);
                  //     DownloadFilePdf(item);
                  //   }}
                  //   key={item.id}
                  // >
                  <div onClick={() => DownloadPdf(item)} key={item.id}>
                    <strong
                      style={{
                        margin: 0,
                      }}
                    >
                      {item?.id}
                    </strong>
                    <p
                      style={{
                        margin: 0,
                        marginLeft: "50px ",
                      }}
                    >
                      {item?.principleOfCaseLaws}
                    </p>
                    {/* </div> */}
                  </div>
                ))}
            </LibraryListCard>
          </Grid>
        </Grid>
        <LibraryListsContainer>
          <div></div>
          <LibraryListCard>
            <strong
              style={{
                backgroundColor: "#3C3D37",
                position: "sticky",
                top: 0,
                color: "#fff",
              }}
            >
              Statutes
            </strong>
            {data !== undefined &&
              data.statutes !== undefined &&
              data?.statutes.map((item) => (
                <div onClick={() => onCheckLogin(item.file)} key={item.id}>
                  <span>{item.law_or_statute}</span>
                </div>
              ))}
          </LibraryListCard>
          <LibraryListCard>
            <strong
              style={{
                backgroundColor: "#3C3D37",
                position: "sticky",
                top: 0,
                color: "#fff",
              }}
            >
              Notification
            </strong>
            {data !== undefined &&
              data.notifications !== undefined &&
              data?.notifications.map(
                (item) =>
                  item.subject && (
                    <div onClick={() => onCheckLogin(item.file)} key={item.id}>
                      <span>{item.subject}</span>
                    </div>
                  )
              )}
          </LibraryListCard>
        </LibraryListsContainer>
      </MainContainer>
    </ResearchContainer>
  );
};

export default Dashboard;
