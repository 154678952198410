import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar"; // Import AppBar
import { useNavigate, useLocation, Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import "./sidenav.css";
import Mbj from "../../../assets/MBJ.png";
import TopLevelHeader from "../header/TopLevelHeader";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux-store/user/userReducer";
import DashFooter from "../footer/DashFooter";

const drawerWidth = 240;

function SideNav({ window, children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    setMobileOpen(false); // Close the drawer after navigation on mobile
  };

  const isActive = (route) => location.pathname === route;

  const drawer = (
    <div>
      <Toolbar />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          onClick={() => navigate("/library/dashboard")}
          style={{ marginBottom: "40px", cursor: "pointer", height: "70px" }}
          src={Mbj}
          alt="logo"
        />
      </div>
      <List>
        {[
          { text: "Dashboard", route: "/library/dashboard" },
          { text: "Case Law", route: "/library/case-law" },
          { text: "Statutes", route: "/library/statutes" },
          { text: "Notifications", route: "/library/notification" },
          { text: "Dictionary", route: "/library/dictionary" },
          // { text: "Upload Your Case", route: "/library/upload" },
          // { text: "Request Case", route: "/library/request" },
          { text: "Contact Us", route: "/library/contact" },
        ].map((item, index) => (
          <React.Fragment key={item.text}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleMenuItemClick(item.route)}
                sx={{
                  backgroundColor: isActive(item.route)
                    ? "rgba(245, 245, 247, 0.9)"
                    : "transparent",
                  color: isActive(item.route) ? "#000" : "#fff",
                  "&:hover": {
                    backgroundColor: "rgba(245, 245, 247, 0.4)", // Hover background color
                    color: "#000", // Hover text color
                  },
                }}
              >
                <ListItemText
                  primary={item.text}
                  sx={{
                    fontWeight: isActive(item.route) ? "bold" : "normal",
                    marginLeft: "50px",
                  }}
                />
              </ListItemButton>
            </ListItem>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ borderBottom: "1px solid #5F6F65", width: "50%" }}>
                {" "}
              </div>
            </div>
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="top-level-header">
        <TopLevelHeader />
      </div>
      {/* <div style={{ height: "10px", backgroundColor: "#323232" }}></div> */}
      <Box sx={{ display: "flex", position: "relative" }}>
        <CssBaseline />

        {/* AppBar for mobile menu button */}
        <AppBar
          position="fixed"
          sx={{
            display: { sm: "none" }, // Show only on mobile
            backgroundColor: "black", // Background color matching the drawer
          }}
        >
          <Toolbar style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                // backgroundColor:'green'
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  MbJlaw
                </Typography>
              </div>
              <div>
                {isLoggedIn ? (
                  <div>
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "white",
                      }}
                      onClick={handleClick}
                    >
                      {user.email}
                    </span>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      style={{
                        fontSize: "12px",
                        // zIndex:1600
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          user.user_role === "library"
                            ? navigate("/library/dashboard")
                            : navigate("/fileYourTaxReturn")
                        }
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem onClick={() => dispatch(logout())}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <Link to={"/"}>Login/Registration</Link>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* Drawer for mobile */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                // zIndex: 1300, // Ensures it's higher on mobile
                backgroundColor: "#3C3D37", // Set the background color to #3C3D37
                color: "white", // Optional: Set text color to white
              },
            }}
          >
            {drawer}
          </Drawer>

          {/* Drawer for desktop */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                // zIndex: 1200, // zIndex for desktop drawer
                backgroundColor: "#3C3D37", // Set the background color to #3C3D37
                color: "white", // Optional: Set text color to white
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)`, minHeight: "100vh" },
            zIndex: 1,
          }}
        >
          {/* Render the component passed as a prop */}
          {children}
        </Box>
      </Box>
      <div className="dash-footer">
        <DashFooter />
      </div>
    </>
  );
}

SideNav.propTypes = {
  window: PropTypes.func,
  component: PropTypes.elementType, // Expect a component type
};

export default SideNav;
