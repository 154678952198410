import React from 'react';
import { useSelector } from 'react-redux';
import CustomPaginationActionsTable from '../../components/table/Table';

const DictionaryList = () => {
  const searchedDictionary = useSelector(
    (state) => state.library.searchedDictionary
  );
  return (
    <div>
      <h3>Searched Dictionary</h3>
      <CustomPaginationActionsTable
        rows={searchedDictionary}
        head={[
          { title: 'MBJlaw#', width: '10%', key: 'id' },
          { title: 'Word', width: '30%', key: 'word' },
          { title: 'Meaning', width: '50%', key: 'meaning' },
          { title: 'Download', width: '10%' },
        ]}
      />
    </div>
  );
};

export default DictionaryList;
