import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../redux-store/user/userReducer";
import { MainContainer } from "../../style/StyleComponent";
import "./TopLevelHeader.css";

const TopLevelHeader = () => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ backgroundColor: "#000000" }}>
      <MainContainer
        id="topLevelNavBar"
        bgColor="#000000"
        flexDirection="row"
        justifyContent="space-between"
        paddingVertical="10px"
        // style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
       <div className="tLHInfoContainer">
        <a className="TLHInfo" href="mailto:mbjlaw.pk@gmail.com">
        mbjlaw.pk@gmail.com
        </a>
        <span className="TLHInfo">+92 323-4072036</span>
      </div>
        <div className="tLHButtonContainer">
          {/* <Link to={"/faq"}>Help</Link> */}
          {isLoggedIn ? (
            <div>
              <span
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
                onClick={handleClick}
              >
                {user.email}
              </span>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                style={{
                  fontSize: "12px",
                  // zIndex:1600
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() =>
                    user.user_role === "library"
                      ? navigate("/library/dashboard")
                      : navigate("/fileYourTaxReturn")
                  }
                >
                  Dashboard
                </MenuItem>
                <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            <Link to={"/registration"}>Registration</Link>
          )}
        </div>
      </MainContainer>
    </div>
  );
};

export default TopLevelHeader;
