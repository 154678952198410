import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import AboutUs from "./pages/about/AboutUs";
import ActOrdinaceRule from "./pages/act-ordinace/ActOrdinaceRule";
import ActOrdinanceDetails from "./pages/act-ordinace/page/ActOrdinanceDetails";
import Blogs from "./pages/blogs/Blogs";
import ContactPage from "./pages/contact/ContactPage";
import Faq from "./pages/faq/Faq";
import Privacy from "./pages/genrals/Privacy";
import TearmsAndConditions from "./pages/genrals/TearmsAndConditions";
import HeaderFooter from "./pages/HeaderFooter";
import HomePage from "./pages/Home/HomePage";
import NewPage from "./pages/Home/lexPressHome";
import Login from "./pages/login/Login";
import OverseasPage from "./pages/overseas/OverseasPage";
import Registration from "./pages/registration/Registration";
import BlogsResearch from "./pages/research-add-insight/blogs/BlogsResearch";
import ResearchAddInsight from "./pages/research-add-insight/ResearchAddInsight";
import SaleTaxCalculator from "./pages/sale-tax-calculator/SaleTaxCalculator";
import Services from "./pages/services/Services";
import { LibraryRoutes } from "./panels/library/routes/LibraryRoutes";
import PrivateRoute from "./routes/private.routes";
import SideNav from "./global/components/sidenav/SideNav";
import Dashboard from "./panels/library/pages/dashboard/Dashboard";
import GetInTouch from "./pages/contact/components/getInTouch/GetInTouch";
import BlogDetails from "./pages/blogs/blogs-section/BlogDetails";

function App() {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);

  return (
    <BrowserRouter>
      <Routes>
        {isLoggedIn && (
          <>
            <Route element={<PrivateRoute user={isLoggedIn} />}>
              {LibraryRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <SideNav>{route.component}</SideNav>
                    // <HeaderFooter isLibrary={true}>{route.component}</HeaderFooter>
                  }
                />
              ))}
              {/* {dashboardRoutes.map((route, index) =>
            route.children ? (
              route.children.map((route, index) =>
                route.children ? (
                  route.children.map((route, index) => (
                    <Route
                      key={index}
                      path={`${route.path}`}
                      element={route.Component}
                    />
                  ))
                ) : (
                  <Route
                    key={index}
                    path={`${route.path}`}
                    element={route.Component}
                  />
                )
              )
            ) : (
              <Route
                key={index}
                path={`${route.path}`}
                element={route.Component}
              />
            )
          )} */}
            </Route>
            <Route
              path="*"
              element={<Navigate to="/library/dashboard" replace />}
            />
          </>
        )}
        {!isLoggedIn && (
          <>
            <Route
              path="/contact"
              element={
                <HeaderFooter>
                  {/* <ContactPage /> */}
                  <GetInTouch />
                </HeaderFooter>
              }
            />
            <Route
              path="/salary-tax-calculator"
              element={
                <HeaderFooter>
                  <SaleTaxCalculator />
                </HeaderFooter>
              }
            />
            {/* <Route
          path="/login"
          element={
            <HeaderFooter>
              <Login />
            </HeaderFooter>
          }
        /> */}
            <Route
              path="/registration"
              element={
                <HeaderFooter>
                  <Registration />
                </HeaderFooter>
              }
            />
            <Route
              path="/case-law-library"
              element={
                <HeaderFooter>
                  <ResearchAddInsight />
                </HeaderFooter>
              }
            />
            <Route
              path="/about-us"
              element={
                <HeaderFooter>
                  <AboutUs />
                </HeaderFooter>
              }
            />
            <Route
              path="/overseas-chapter"
              element={
                <HeaderFooter>
                  <OverseasPage />
                </HeaderFooter>
              }
            />
            <Route
              path="/services"
              element={
                <HeaderFooter>
                  <Services />
                </HeaderFooter>
              }
            />
            <Route
              path="/act-ordinance-rules"
              element={
                <HeaderFooter>
                  <ActOrdinaceRule />
                </HeaderFooter>
              }
            />
            <Route
              path="/act-ordinance-rules/detail/:name/:id"
              element={
                <HeaderFooter>
                  <ActOrdinanceDetails />
                </HeaderFooter>
              }
            />
            <Route
              path="/research-and-insight"
              element={
                <HeaderFooter>
                  <BlogsResearch />
                </HeaderFooter>
              }
            />
            <Route
              path="/faq"
              element={
                <HeaderFooter>
                  <Faq />
                </HeaderFooter>
              }
            />
            <Route
              path="/blogs"
              element={
                <HeaderFooter>
                  <Blogs />
                </HeaderFooter>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <HeaderFooter>
                  <Privacy />
                </HeaderFooter>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <HeaderFooter>
                  <TearmsAndConditions />
                </HeaderFooter>
              }
            />
            <Route
              path="/"
              element={
                <HeaderFooter>
                  <NewPage />
                </HeaderFooter>
              }
            />
            <Route
              path="/new-page"
              element={
                <HeaderFooter>
                  <NewPage />
                </HeaderFooter>
              }
            />
            <Route
              path="/testing"
              element={
                <SideNav>
                  <Dashboard />
                </SideNav>
              }
            />
            <Route
              path="/blog-details"
              element={
                <HeaderFooter>
                  <BlogDetails />
                </HeaderFooter>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}

        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
