import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Btn,
  ErrorText,
  Input,
  LoginRegistrationContainer,
  MainContainer,
  Select,
} from "../../global/style/StyleComponent";
import { userRegister } from "../../redux-store/user/actionCreator";

const Registration = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
    phone: "",
    type: "library",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
    phone: "",
    type: "",
  });
  const a = "Home > SignUp";
  return (
    <>
      <div
        style={{
          borderTop: "1px solid black",
          // height: "70px",
          backgroundColor: "#323232",
          paddingLeft: "50px",
          color: "white",
        }}
      >
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "5px" }}>
          {/* <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Login</h1> */}
          {/* <br /> */}
          <p>{a}</p>
        </div>
      </div>
      <MainContainer
        paddingVertical={"50px"}
        justifyContent={"center"}
        alignItem={"center"}
      >
        <LoginRegistrationContainer>
          <h2 style={{ color: "black" }}>Registration</h2>
          <Input
            id="username"
            label="Name"
            placeholder="Name"
            style={{ filter: "drop-shadow(-2px 9px 3px #c0afaf" }}
            onChange={(e) => setData({ ...data, username: e.target.value })}
            required
          />

          <ErrorText>{error.username}</ErrorText>
          <Input
            id="email"
            label="Email"
            placeholder="Email"
            type={"email"}
            style={{ filter: "drop-shadow(-2px 9px 3px #c0afaf" }}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />

          <ErrorText>{error.email}</ErrorText>
          <Input
            id="phone"
            label="Phone"
            placeholder="Phone"
            type={"number"}
            style={{ filter: "drop-shadow(-2px 9px 3px #c0afaf" }}
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />

          <ErrorText>{error.phone}</ErrorText>
          <Input
            id="password"
            label="Password"
            type={"password"}
            placeholder="Password"
            style={{ filter: "drop-shadow(-2px 9px 3px #c0afaf" }}
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />

          <ErrorText>{error.password}</ErrorText>
          <Input
            id="confirmPassword"
            label="Confirm Password"
            type={"password"}
            placeholder="Confirm Password"
            style={{ filter: "drop-shadow(-2px 9px 3px #c0afaf" }}
            onChange={(e) =>
              setData({ ...data, confirmPassword: e.target.value })
            }
          />
          <ErrorText>{error.confirmPassword}</ErrorText>
          {/* <Select
          id='type'
          onChange={(e) => setData({ ...data, type: e.target.value })}
        >
          <option value={''}>Select account type</option>
          <option value={'library'}>Library</option>
          <option value={'ntn'}>Tax Portal</option>
        </Select> */}
          {/* <ErrorText>{error.type}</ErrorText> */}

          <Btn
            onClick={() => {
              var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (data.username === "") {
                setError({ ...error, username: "Name is required", email: "" });
              } else if (data.email === "") {
                setError({ ...error, email: "Email is required" });
              } else if (!data.email.match(mailformat)) {
                setError({
                  ...error,
                  email: "Email is not valid",
                  password: "",
                });
              } else if (data.phone === "") {
                setError({
                  ...error,
                  phone: "Phone is required",
                  email: "",
                  username: "",
                  type: "",
                });
              } else if (data.type === "") {
                setError({
                  ...error,
                  phone: "",
                  email: "",
                  username: "",
                  type: "Type is required",
                });
              } else if (data.password === "") {
                setError({
                  ...error,
                  password: "Password is required",
                  email: "",
                  username: "",
                  type: "",
                  phone: "",
                });
              } else if (data.confirmPassword === "") {
                setError({
                  ...error,
                  confirmPassword: "Confirm Password is required",
                  email: "",
                  username: "",
                  type: "",
                  phone: "",
                  password: "",
                });
              } else if (data.password !== data.confirmPassword) {
                setError({
                  ...error,
                  confirmPassword: "Password not match",
                  email: "",
                  username: "",
                  type: "",
                  phone: "",
                  password: "",
                });
              } else {
                setError({
                  email: "",
                  password: "",
                  confirmPassword: "",
                  type: "",
                  username: "",
                  phone: "",
                });
                dispatch(userRegister(data, navigation));
              }
            }}
            fontSize="18px"
          >
            Register
          </Btn>

          <span style={{color:'black'}}>Already have an account?</span >
          <Link style={{color:'black'}} to="/">Login</Link>
        </LoginRegistrationContainer>
      </MainContainer>
    </>
  );
};

export default Registration;
