import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Facebook from "../../../../assets/facebook-f-brands.svg";
import Insta from "../../../../assets/instagram-brands.svg";
import LinkedIn from "../../../../assets/linkedin-brands.svg";
import Twitter from "../../../../assets/twitter-brands.svg";
import { Btn, MainContainer } from "../../../../global/style/StyleComponent";
import { setAppointment } from "../../../../redux-store/home/homeReducer";
import "./GetInTouch.css";
import AxiosCall from "../../../../api/AxiosCall";
import { errorToast, successToast } from "../../../../global/toast";

const GetInTouch = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const a = "Home > Contact";
  return (
    <div style={{ minHeight: "100vh" }}>
      {!isLoggedIn && (
        <div
          style={{
            borderTop: "1px solid black",
            // height: "70px",
            backgroundColor: "#323232",
            paddingLeft: "50px",
            color: "white",
          }}
        >
          <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "5px" }}>
            {/* <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Login</h1> */}
            {/* <br /> */}
            <p>{a}</p>
          </div>
        </div>
      )}

      <MainContainer id="getInTouch" height={"750px"}>
        <div className="getInTouchContainer">
          <div className="getInTouchRight">
            <strong>Send a Message</strong>
            <div>
              <input
                value={data.name}
                onChange={(e) => {
                  setData({
                    ...data,
                    name: e.target.value,
                  });
                }}
                type="text"
                placeholder="Name"
              />
              <input
                value={data.email}
                onChange={(e) => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
                type="email"
                placeholder="Email"
              />
              <input
                value={data.phone}
                onChange={(e) => {
                  setData({
                    ...data,
                    phone: e.target.value,
                  });
                }}
                type="number"
                placeholder="Phone"
              />
              <textarea
                maxLength={"600"}
                value={data.message}
                onChange={(e) => {
                  setData({
                    ...data,
                    message: e.target.value,
                  });
                }}
                placeholder="Message"
              />
              <Btn
                onClick={() => {
                  if (
                    data.phone.length >= 11 &&
                    data.email !== "" &&
                    data.message !== "" &&
                    data.name !== ""
                  ) {
                    AxiosCall.post("/query/create", {
                      ...data,
                      isService: 0,
                    })
                      .then((res) => {
                        successToast(res.data.data);
                        setData({
                          name: "",
                          phone: "",
                          email: "",
                          message: "",
                        });
                      })
                      .catch((err) => {
                        setData({
                          name: "",
                          phone: "",
                          email: "",
                          message: "",
                        });
                        errorToast(
                          "Unable to perform your action at this moment "
                        );
                      });
                  } else {
                    alert("all field are required");
                  }
                }}
                id="getInTouchMessage"
              >
                Send Message
              </Btn>
            </div>
          </div>
          <div className="getInTouchLeft">
            <strong>Get In Touch</strong>
            <p>
              Our team serves our clients round the clock. We believe in
              provision of services on the Go. Pick up a meeting slot of your
              choice and let our experts serve you at your time of convenience.
            </p>
            {/* <Btn
              id="getInTouchBtn"
              width={"70%"}
              fontSize={"25px"}
              onClick={() => dispatch(setAppointment())}
            >
              Free Appointment
            </Btn> */}
            <strong
              style={{
                marginTop: "20px",
              }}
            >
              Found us
            </strong>
            <div className="foundUsContainer">
              <a
                target="blank"
                href="https://www.facebook.com/taxpresspk"
                className="socialLink"
              >
                <img src={Facebook} alt="" />
              </a>
              <a
                target="blank"
                href="https://www.instagram.com/taxpresspk/"
                className="socialLink"
              >
                <img src={Insta} alt="" />
              </a>
              <a
                target="blank"
                href="https://twitter.com/Tax_press_pk"
                className="socialLink"
              >
                <img src={Twitter} alt="" />
              </a>
              <a
                target="blank"
                href="https://www.linkedin.com/in/tax-press-725982238"
                className="socialLink"
              >
                <img src={LinkedIn} alt="" />
              </a>
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default GetInTouch;
