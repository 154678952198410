import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function BlogDetails() {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const location = useLocation();
  const { card } = location.state || {};

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);
  const a = `Home > Blogs > ${card?.title}`;
  return (
    <div style={{ minHeight: "100vh" }}>
      {!isLoggedIn && (
        <div
          style={{
            borderTop: "1px solid black",
            // height: "70px",
            backgroundColor: "#323232",
            paddingLeft: "50px",
            color: "white",
          }}
        >
          <div style={{ maxWidth: "1250px", margin: "0 auto", padding: "5px" }}>
            {/* <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>Login</h1> */}
            {/* <br /> */}
            <p>{a}</p>
          </div>
        </div>
      )}
      <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
        <h1>{card?.title}</h1>
        <h3>{card?.author}</h3>
        <p>{card?.date}</p>
        <p></p>
        <img
          src={"https://api.taxpress.com.pk/" + card?.file}
          alt="Avatar"
          style={{ width: "100%", objectFit: "cover" }}
        />

        <div dangerouslySetInnerHTML={{ __html: card?.paragraph }} />
      </div>
    </div>
  );
}
