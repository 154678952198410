import styled from "styled-components";

const Btn = styled.button`
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  width: ${(props) => (props.width ? props.width : "250px")};
  font-weight: 600;
  margin-left: ${({ marginLeft }) => marginLeft || "5px"};
  margin-right: ${({ marginRight }) => marginRight || "5px"};
  cursor: pointer;
  background-color: ${(props) => props.color || "#323232"};
  border: none;
  border-radius: 50px;
  align-self: ${(props) => props.alignSelf};
  opacity: 1;
  z-index: 1;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.textColor || "#fff"};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};

  &:hover {
    background-color: ${(props) => props.hoverColor || "#FF9100"};
    // color: ${(props) => props.hoverTextColor || "#fff"};
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }
`;

const MainContainer = styled.div`
  padding: ${({ paddingVertical }) => paddingVertical || "0px"} 150px;
  padding-right: ${({ paddingRight }) => paddingRight};
  z-index: 1500;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  background-color: ${(props) => props.bgColor || "#fff"};
  height: ${(props) => props.height || "100%"};
  overflow:auto;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "column"};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItem};
  position: relative;
  @media (max-width: 1500px) {
    padding: ${({ paddingVertical }) => paddingVertical || "0px"} 120px;
    padding-right: ${({ paddingRight }) => paddingRight};
    padding-left: ${({ paddingLeft }) => paddingLeft};
  }
  @media (max-width: 1200px) {
    padding: ${({ paddingVertical }) => paddingVertical || "0px"} 100px;
    padding-right: ${({ paddingRight }) => paddingRight};
    padding-left: ${({ paddingLeft }) => paddingLeft};
  }
  @media (max-width: 800px) {
    padding: ${({ paddingVertical }) => paddingVertical || "0px"} 70px;
    padding-right: ${({ paddingRight }) => paddingRight};
    padding-left: ${({ paddingLeft }) => paddingLeft};
  }
  @media (max-width: 630px) {
    padding: ${({ paddingVertical }) => paddingVertical || "0px"} 50px;
    padding-right: ${({ paddingRight }) => paddingRight};
    padding-left: ${({ paddingLeft }) => paddingLeft};
  }
  @media (max-width: 500px) {
    padding: ${({ paddingVertical }) => paddingVertical || "0px"} 30px;
    padding-right: ${({ paddingRight }) => paddingRight};
    padding-left: ${({ paddingLeft }) => paddingLeft};
  }
`;

const HalfDiv = styled.div`
  width: 50%;
  height: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.bgColor || "#fff"};
  padding: 20px;
  border-radius: 7px;
  color: #194173;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  & > span {
    font-size: 25px;
    font-weight: bold;
  }
  & > p {
    font-size: 18px;
    text-align: justify;
    height: 120px;
  }
  &:nth-child(odd) {
    margin-right: ${(props) => props.marginRight || "10px"};
  }
  & > .cardReadMoreBtn {
    display: flex !important;
  }
  & > .imgCardFile {
    width: 50px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg {
      width: 40px;
    }
  }
  &:hover {
    background-color: #194173;
    color: #fff;
    .imgCardFile {
      path {
        fill: #fcc028;
      }
    }
    .cardReadMoreBtn > span {
      color: #fcc028;
    }
    .cardReadMoreBtn > img {
      filter: invert(87%) sepia(20%) saturate(4395%) hue-rotate(338deg)
        brightness(106%) contrast(98%);
    }
  }
  @media (max-width: 900px) {
    & > p {
      height: 130px;
    }
  }
  @media (max-width: 876px) {
    & > p {
      height: 150px;
    }
  }
  @media (max-width: 768px) {
    & > p {
      height: 70px;
    }
  }
  @media (max-width: 682px) {
    & > p {
      height: 90px;
    }
  }
  @media (max-width: 350px) {
    & > p {
      height: 110px;
    }
  }
  @media (max-width: 500px) {
    & > .imgCardFile {
      width: 25px;
    }
    & > span {
      font-size: 20px !important;
    }
    & > p {
      font-size: 14px !important;
      text-align: justify !important;
    }
    & > .cardReadMoreBtn > img {
      width: 25px;
    }
  }
  @media (max-width: 425px) {
    flex: 1;
  }
`;

const MapUser = styled.img`
  position: absolute;
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  bottom: ${(props) => props.bottom && props.bottom};
  width: ${(props) => props.width || "50px"};
  height: ${(props) => props.height || "50px"};
  border-radius: 100%;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: ${(props) => props.alignItem && "center"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  color: ${(props) => props.color || "#000"};
  padding: ${(props) => props.padding};
  flex: ${(props) => props.flex};
`;

const FloatingContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  width: ${(props) => props.width};
`;

const LoginRegistrationContainer = styled.div`
  display: flex;
  background-color: rgba(245, 245, 247, 0.9);
  box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, 0.5);
  padding: 2rem 70px;
  min-width: 25%;
  height: fit-content;
  width: ${({ width }) => width};
  min-height: ${({ height }) => height};
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 3px;
  border-radius: 20px;
  border: 4px solid #323232;
  display: flex;
  flex-direction: column;
  color: #fff;
  & form {
    display: flex;
    flex-direction: column;
  }
  & h2 {
    font-size: 45px;
    color: #ff8e02;
    margin: 1rem 0rem;
    align-self: center;
  }
  & span {
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
  }
  & a {
    color: #fff;
    align-self: center;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 10px;
    &:hover {
      background-color: #fcc028;
      border-radius: 5px;
      color: #373f68;
    }
  }
  & label {
    color: #fff;
  }
  & span {
    color: #fff;
  }
  & button {
    align-self: center;
  }
  @media (max-width: 500px) {
    padding: 30px 30px;
    width: 80%;
    & h2 {
      font-size: 30px;
    }
    & span {
      font-size: 14px;
    }
    & #login-btn {
      font-size: 14px;
      width: 100% !important;
    }
  }
`;

const Input = styled.input`
  height: ${(props) => props.height || "40px"};
  border: 1px solid #373f68;
  border-radius: 50px;
  padding: ${(props) => props.padding || "5px 20px"};
  font-size: ${(props) => props.fontSize || "14px"};
  // margin-bottom: 20px;
  color: ${(props) => props.color || "#373f68"};
  background-color: ${(props) => props.bgColor || "#fff"};
  &:focus {
    outline: none;
  }
`;

const Select = styled.select`
  height: ${(props) => props.height || "40px"};
  border: 1px solid #373f68;
  border-radius: 5px;
  padding: ${(props) => props.padding || "5px 10px"};
  font-size: ${(props) => props.fontSize || "14px"};
  color: ${(props) => props.color || "#373f68"};
  background-color: ${(props) => props.bgColor || "#fff"};
  &:focus {
    outline: none;
  }
  select.selector {
    padding: ${(props) => props.padding || "5px 10px"};
    min-height: 2em;
  }
`;
const TextArea = styled.textarea`
  width: ${(props) => props.width || "95%"};
  height: ${(props) => props.height || "40px"};
  border: none;
  border-radius: 5px;
  padding: ${(props) => props.padding || "5px 10px"};
  font-size: ${(props) => props.fontSize || "14px"};
  color: ${(props) => props.color || "#373f68"};
  background-color: ${(props) => props.bgColor || "#fff"};
  &:focus {
    outline: none;
  }
`;

const ErrorText = styled.span`
  color: #fcc028;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  width: 100%;
`;

const SectionHeroContainer = styled.div`
  background-image: url(${(props) => props.bgImage});
  height: 250px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  padding: 0px 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  & > strong {
    color: #fff;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    z-index: 1;
  }

  & > span {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 1;
  }
  @media (max-width: 1050px) {
    padding: 0px 100px;
    height: 370px;
    & > strong {
      font-size: 2rem;
    }
    & > span {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 750px) {
    padding: 0px 50px;
    height: 270px;
    & > strong {
      font-size: 1.5rem;
    }
    & > span {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 550px) {
    padding: 0px 30px;
    height: 200px;
    & > strong {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
    & > span {
      font-size: 0.6rem;
    }
  }
  @media (max-width: 425px) {
    padding: 0px 20px;
    & > strong {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    & > span {
      font-size: 0.5rem;
    }
  }
`;

export {
  Btn,
  MainContainer,
  HalfDiv,
  Card,
  MapUser,
  Container,
  FloatingContainer,
  LoginRegistrationContainer,
  Input,
  ErrorText,
  TextArea,
  SectionHeroContainer,
  Select,
};
