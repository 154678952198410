import Dashboard from '../pages/dashboard/Dashboard';
import {
  Folder,
  HomeOutlined,
  FolderOutlined,
  Notifications,
  DocumentScanner,
} from '@mui/icons-material';
import CaseLawSearch from '../pages/caselaw/CaseLawSearch';
import StatutesSearch from '../pages/statutes/StatutesSearch';
import NotificationSearch from '../pages/notification/NotificationSearch';
import DictionarySearch from '../pages/lawDictionary/DictionarySearch';
import PdfGenerater from '../components/table/pdfGenerater';
import UploadCase from '../../../pages/uploadCase/UploadCase';
import RequestCase from '../../../pages/RequestCase/RequestCase';
import Privacy from '../../../pages/genrals/Privacy';
import TearmsAndConditions from '../../../pages/genrals/TearmsAndConditions';
import GetInTouch from '../../../pages/contact/components/getInTouch/GetInTouch';
import Blogs from '../../../pages/blogs/Blogs';
import BlogDetails from '../../../pages/blogs/blogs-section/BlogDetails';

export const LibraryRoutes = [
  {
    title: 'Dashboard',
    path: '/library/dashboard',
    component: <Dashboard />,
    isMain: true,
    icon: <HomeOutlined />,
  },
  {
    title: 'Case Law',
    path: '/library/case-law',
    component: <CaseLawSearch />,
    isMain: true,
    icon: <Folder />,
  },
  {
    title: 'PDF Generater',
    path: '/library/pdf-generater',
    component: <PdfGenerater />,
    isMain: true,
    icon: <Folder />,
  },
  {
    title: 'Statutes',
    path: '/library/statutes',
    component: <StatutesSearch />,
    isMain: true,
    icon: <FolderOutlined />,
  },
  {
    title: 'Notification',
    path: '/library/notification',
    component: <NotificationSearch />,
    isMain: true,
    icon: <Notifications />,
  },
  {
    title: 'Dictionary',
    path: '/library/dictionary',
    component: <DictionarySearch />,
    isMain: true,
    icon: <DocumentScanner />,
  },
  // {
  //   title: 'UploadCase',
  //   path: '/library/upload',
  //   component: <UploadCase />,
  //   isMain: true,
  //   icon: <DocumentScanner />,
  // },
  // {
  //   title: 'RequestCase',
  //   path: '/library/request',
  //   component: <RequestCase />,
  //   isMain: true,
  //   icon: <DocumentScanner />,
  // },
  {
    title: 'RequestCase',
    path: '/library/privacy',
    component: <Privacy />,
    isMain: true,
    icon: <DocumentScanner />,
  },
  {
    title: 'RequestCase',
    path: '/library/terms',
    component: <TearmsAndConditions />,
    isMain: true,
    icon: <DocumentScanner />,
  },
  {
    title: 'RequestCase',
    path: '/library/contact',
    component: <GetInTouch />,
    isMain: true,
    icon: <DocumentScanner />,
  },
  {
    title: 'RequestCase',
    path: '/library/blogs',
    component: <Blogs />,
    isMain: true,
    icon: <DocumentScanner />,
  },
  {
    title: 'RequestCase',
    path: '/library/blog-details',
    component: <BlogDetails />,
    isMain: true,
    icon: <DocumentScanner />,
  },
];
