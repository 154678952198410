import React from "react";
import Footer from "../global/components/footer/Footer";
import NavHeader from "../global/components/header/NavHeader";
import TopLevelHeader from "../global/components/header/TopLevelHeader";
import TopLevelHeaderDash from "../global/components/header/TopLevelForDash";
import NavBarLibrary from "../panels/library/components/navbar/NavBarLibrary";
import Features from "./Home/components/bottom/Features";
import { useSelector } from "react-redux";
import SideNav from "../global/components/sidenav/SideNav";

const HeaderFooter = ({ children, isLibrary }) => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  console.log("Log in or not", isLoggedIn);
  return (
    <>
      {/* {isLibrary ? <NavBarLibrary /> : <NavHeader />} */}
      {isLoggedIn ? (
        <TopLevelHeaderDash />
      ) : (
        <>
          <TopLevelHeader />
          <NavHeader />
        </>
      )}
      {/* <NavHeader /> */}

      {children}
      <Features />
      {/* <Footer /> */}
      <div style={{ backgroundColor: "black" }}>
        <div
          className="footerCopyWrite"
          style={{ maxWidth: "1200px", margin: "0 auto" }}
        >
          <strong>© Copyright - MBJlaw 2024</strong>
          <div className="black-footer-pages">
            <div>FAQ's</div>

            <div style={{ textDecoration: "none" }}>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="/blogs"
              >
                Blogs
              </a>
            </div>
            <div style={{ textDecoration: "none" }}>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="/privacy-policy"
              >
                Privacy Policy
              </a>
            </div>
            <div style={{ textDecoration: "none" }}>
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="/terms-and-conditions"
              >
                T&C's
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderFooter;
