import { Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../redux-store/user/userReducer";
import { MainContainer } from "../../style/StyleComponent";

const DashFooter = () => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const navigate = useNavigate();
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ backgroundColor: "#000000" }}>
      <MainContainer
        id="topLevelNavBar"
        bgColor="#000000"
        flexDirection="row"
        justifyContent="space-between"
        paddingVertical="10px"
        // style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className="tLHInfoContainer">
          <span className="TLHInfo">©copyright- MbJlaw 2024</span>
        </div>
        <div className="tLHButtonContainer">
          <div className="TLHInfo">FAQ's</div>
          <div className="TLHInfo">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/library/blogs"
            >
              Blogs
            </a>
          </div>
          <div className="TLHInfo">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/library/privacy"
            >
              Privacy Policy
            </a>
          </div>
          <div className="TLHInfo">
            {" "}
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/library/terms"
            >
              T&C's
            </a>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default DashFooter;
