import { Close } from '@mui/icons-material';
import { IconButton, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AxiosCall from '../../api/AxiosCall';
import HeroSections from '../../global/components/hero-section/HeroSections';
import {
  Btn,
  Container,
  Input,
  LoginRegistrationContainer,
  MainContainer,
} from '../../global/style/StyleComponent';
import { errorToast } from '../../global/toast';
import { getDashboard } from '../../redux-store/library/actionCreator';
import {
  ActionBtnCard,
  LibraryCardsContainer,
  LibraryCountCard,
  LibraryListCard,
  LibraryListsContainer,
  ResearchContainer,
  ResearchHeading,
  ResearchParagraph,
} from './ResearchAndInsightStyle';

const ResearchAddInsight = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.library.dashboard);
  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const user = useSelector((state) => state.users.user);
  const [modal, setModal] = useState({
    request: false,
    upload: false,
  });

  const [_case, setCase] = useState({
    name: '',
    phone: '',
    email: '',
    caseNo: '',
  });

  function DownloadFile(fileName) {
    fetch(`${process.env.REACT_APP_FILE_DOMAIN}${fileName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'taxpress.pdf';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  }
 
  const onCheckLogin = (link) => {
    if (!isLoggedIn || user.user_role !== 'library') {
      navigate('/');
    } else {
      if (typeof link === 'string') DownloadFile(link);
      else
        setModal({
          ...modal,
          [link.route]: true,
        });
    }
  };

  function DownloadPdf(fileData) {
    // console.log("on click py mil rha hoon", fileData);
    // const encodedFileData = encodeURIComponent(fileData);
    // console.log(encodedFileData);
    navigate("/library/pdf-generater", {
      state: {
        // ...values
        fileData: fileData,
      },
    });

    // fetch(`${fileName}`)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement("a");
    //     a.href = url;
    //     a.download = "taxpress.pdf";
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //     window.URL.revokeObjectURL(url);
    //   });
  }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ResearchContainer>
      <Modal open={modal.request}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <LoginRegistrationContainer
            width={'fit-content'}
            height={'fit-content'}
            style={{
              padding: '50px 30px',
            }}
          >
            <div
              style={{
                display: 'inherit',
                flexDirection: 'inherit',
                gap: '1em',
                position: 'relative',
              }}
            >
              <IconButton
                style={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  top: '-35px',
                }}
                onClick={() => {
                  setModal({
                    ...modal,
                    request: false,
                  });
                  setCase({
                    name: '',
                    phone: '',
                    email: '',
                    caseNo: '',
                  });
                }}
              >
                <Close
                  style={{
                    color: '#fff',
                  }}
                />
              </IconButton>
              <h2
                style={{
                  margin: '0px',
                  fontSize: '30px',
                }}
              >
                Request
              </h2>
              <Input
                id='case_No'
                label='Case No'
                placeholder='Case No'
                onChange={(e) => setCase({ ..._case, caseNo: e.target.value })}
              />
              <Input
                id='name'
                label='Name'
                placeholder='Name'
                onChange={(e) => setCase({ ..._case, name: e.target.value })}
              />
              {/* <ErrorText>{error.email}</ErrorText> */}
              <Input
                id='phone'
                label='Phone'
                placeholder='Phone'
                type='number'
                onChange={(e) => setCase({ ..._case, phone: e.target.value })}
              />
              {/* <ErrorText>{error.email}</ErrorText> */}
              <Input
                id='email'
                label='Email'
                placeholder='Email'
                onChange={(e) => setCase({ ..._case, email: e.target.value })}
              />
              {/* <ErrorText>{error.email}</ErrorText> */}

              <Btn
                marginTop={'20px'}
                id='login-btn'
                onClick={() => {
                  AxiosCall.post('/query/create', {
                    ..._case,
                    message: _case.caseNo,
                    type: 'request',
                    isService: 1,
                  })
                    .then(() => {
                      setModal({ ...modal, request: false });
                      setCase({
                        name: '',
                        phone: '',
                        email: '',
                        caseNo: '',
                      });
                    })
                    .catch(() => {
                      errorToast(
                        'Unable to perform your action at this moment '
                      );
                    });
                }}
                fontSize='18px'
              >
                Submit
              </Btn>
            </div>
          </LoginRegistrationContainer>
        </div>
      </Modal>
      <Modal open={modal.upload}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <LoginRegistrationContainer
            width={'fit-content'}
            height={'fit-content'}
            style={{
              padding: '50px 30px',
            }}
          >
            <div
              style={{
                display: 'inherit',
                flexDirection: 'inherit',
                gap: '1em',
                position: 'relative',
              }}
            >
              <IconButton
                style={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  top: '-35px',
                }}
                onClick={() => {
                  setModal({
                    ...modal,
                    upload: false,
                  });

                  setCase({
                    name: '',
                    phone: '',
                    email: '',
                    caseNo: '',
                  });
                }}
              >
                <Close
                  style={{
                    color: '#fff',
                  }}
                />
              </IconButton>
              <h2
                style={{
                  margin: '0px',
                  fontSize: '30px',
                }}
              >
                Upload your case
              </h2>
              <Input
                id='case_No'
                label='Case No'
                placeholder='Case No'
                onChange={(e) => setCase({ ..._case, caseNo: e.target.value })}
              />
              <Input
                id='name'
                label='Name'
                placeholder='Name'
                onChange={(e) => setCase({ ..._case, name: e.target.value })}
              />
              {/* <ErrorText>{error.email}</ErrorText> */}
              <Input
                id='phone'
                label='Phone'
                placeholder='Phone'
                type='number'
                onChange={(e) => setCase({ ..._case, phone: e.target.value })}
              />
              {/* <ErrorText>{error.email}</ErrorText> */}
              <Input
                id='email'
                label='Email'
                placeholder='Email'
                onChange={(e) => setCase({ ..._case, email: e.target.value })}
              />
              {/* <ErrorText>{error.email}</ErrorText> */}

              <Btn
                marginTop={'20px'}
                id='login-btn'
                onClick={() => {
                  AxiosCall.post('/query/create', {
                    ..._case,
                    message: _case.caseNo,
                    type: 'upload request',
                    isService: 1,
                  })
                    .then(() => {
                      setModal({ ...modal, request: false });
                      setCase({
                        name: '',
                        phone: '',
                        email: '',
                        caseNo: '',
                      });
                    })
                    .catch(() => {
                      errorToast(
                        'Unable to perform your action at this moment '
                      );
                    });
                }}
                fontSize='18px'
              >
                Submit
              </Btn>
            </div>
          </LoginRegistrationContainer>
        </div>
      </Modal>
      <HeroSections pageName={'Case Law Library'} />
      <MainContainer
        id='researchContainerHeading'
        paddingVertical={'50px'}
        flexDirection={'row'}
      >
        <Container
          direction={'column'}
          flex={'2'}
          justifyContent={'none'}
          marginRight={'10px'}
        >
          <ResearchHeading>
            Explore our vast Library of Tax and Legal Citations
          </ResearchHeading>
          <ResearchParagraph>
            Pakistan’s # 1 portal introducing on demand feature for Court
            Precedents and Legal citations encompassing all tax and fiscal laws.
            <br />
            <br />
            Our case law library comprises a vast database of updated tax and
            legal citations with an additional feature of on demand citations
            with our team out there for your live support.
          </ResearchParagraph>
        </Container>
        <Container
          direction={'column'}
          flex={'1'}
          alignItem={true}
          justifyContent={'space-evenly'}
        >
          <ResearchHeading>Useful Links</ResearchHeading>
          <ActionBtnCard
            onClick={() =>
              isLoggedIn && user.user_role === 'library'
                ? navigate('/library/dashboard')
                : navigate('/')
            }
          >
            <strong>
              {isLoggedIn && user.user_role === 'library'
                ? 'Dashboard'
                : 'Login / Registration'}
            </strong>
          </ActionBtnCard>
          <ActionBtnCard onClick={() => onCheckLogin({ route: 'upload' })}>
            <strong>Upload your case</strong>
          </ActionBtnCard>
          <ActionBtnCard onClick={() => onCheckLogin({ route: 'request' })}>
            <strong>Request Case</strong>
          </ActionBtnCard>
        </Container>
      </MainContainer>
      <MainContainer
        alignItem={'center'}
        flexDirection={'row'}
        bgColor={'#fcc028'}
      >
        <Container
          id='countHeading'
          direction={'column'}
          flex={'1'}
          justifyContent={'center'}
        >
          <ResearchHeading>Our Vast Range of Library</ResearchHeading>
        </Container>
        <Container direction={'column'} flex={'1'} justifyContent={'none'}>
          <LibraryCardsContainer>
            <LibraryCountCard>
              <strong>Total Cases</strong>
              <span>{data?.counts !== undefined && data?.counts.caseLaws}</span>
            </LibraryCountCard>
            <LibraryCountCard>
              <strong>Statues</strong>
              <span>{data?.counts !== undefined && data?.counts.statutes}</span>
            </LibraryCountCard>
            <LibraryCountCard>
              <strong>Notification</strong>
              <span>
                {data?.counts !== undefined && data?.counts.notifications}
              </span>
            </LibraryCountCard>
            <LibraryCountCard>
              <strong>Dictionary</strong>
              <span>
                {data?.counts !== undefined && data?.counts.dictionaries}
              </span>
            </LibraryCountCard>
          </LibraryCardsContainer>
        </Container>
      </MainContainer>
      <MainContainer>
        <LibraryListsContainer>
          <LibraryListCard>
            <strong>Case Laws</strong>
            {data !== undefined &&
              data.caseLaws !== undefined &&
              data?.caseLaws.map((item) => (
                <div onClick={() => DownloadPdf(item)} key={item.id}>
                  <strong
                    style={{
                      margin: 0,
                    }}
                  >
                    {item?.id}
                  </strong>
                  <p
                    style={{
                      margin: 0,
                      marginLeft: '50px ',
                    }}
                  >
                    {item?.principleOfCaseLaws}
                  </p>
                </div>
              ))}
          </LibraryListCard>
          <LibraryListCard>
            <strong>Statutes</strong>
            {data !== undefined &&
              data.statutes !== undefined &&
              data?.statutes.map((item) => (
                <div onClick={() => onCheckLogin(item.file)} key={item.id}>
                  <span>{item.law_or_statute}</span>
                </div>
              ))}
          </LibraryListCard>
          <LibraryListCard>
            <strong>Notification</strong>
            {data !== undefined &&
              data.notifications !== undefined &&
              data?.notifications.map(
                (item) =>
                  item.subject && (
                    <div onClick={() => onCheckLogin(item.file)} key={item.id}>
                      <span>{item.subject}</span>
                    </div>
                  )
              )}
          </LibraryListCard>
        </LibraryListsContainer>
      </MainContainer>
    </ResearchContainer>
  );
};

export default ResearchAddInsight;
